import local from './local'
// import mainnet from './mainnet'
import ronin from './ronin'
import saigon from './saigon'

export default {
  local,
  // mainnet,
  ronin,
  saigon,
}
