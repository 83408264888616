import { getCollectibles, GatewayDefinitions } from '@gnosis.pm/safe-react-gateway-sdk'
import { getClientGatewayUrl, getGnosisNetworkId } from 'src/config'
import { checksumAddress } from 'src/utils/checksumAddress'

export type CollectibleResult = GatewayDefinitions['SafeCollectibleResponse']

export const fetchSafeCollectibles = async (safeAddress: string): Promise<CollectibleResult[]> => {
  const networkId = getGnosisNetworkId()
  const url = getClientGatewayUrl()
  let parsedUrl = url
  if (networkId === '2021') {
    parsedUrl = url.replace('v1', 'v2')
  }
  const collectibles = await getCollectibles(parsedUrl, getGnosisNetworkId().toString(), checksumAddress(safeAddress))
  // FIX ME: actual response and its type doesn't match
  return (collectibles as any)?.results
}
