import web3Utils from 'web3-utils'

const roninPrefix = 'ronin:'

export const isRoninAddress = (address: string): boolean => {
  return web3Utils.isAddress(address.replace('ronin:', '0x'))
}

export const toRoninAddress = (address: string): string => {
  if (web3Utils.isAddress(address)) {
    return address.replace('0x', roninPrefix)
  }

  return address
}
